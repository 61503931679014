import React, { Component } from 'react'

export default class Nav extends Component {
  render() {
    return (
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="#myPage">
              <img className="site-logo" alt="company logo" src="/images/gmyz-logo.png" />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="nav navbar-nav navbar-right">
              <li><a href="/#about">ABOUT</a></li>
              <li><a href="/#services">SERVICES</a></li>
              <li><a href="/#portfolio">PORTFOLIO</a></li>
              {/* <li><a href="/#pricing">PRICING</a></li> */}
              <li><a href="/#contact">CONTACT</a></li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}