import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div id="about" className="container-fluid">
          <div className="row">
              <div className="col-sm-8">
                  <h2>About Us</h2><br />
                  <h4>We help transform businesses by providing industry-oriented strategy, advisory and consulting services in the fast-changing, modern, digital economy</h4><br />
                  <p>Our team has broad and deep experience in helping transform businesses through a focus on data and technology innovation. Our consultants have the experience to understand your business and the goals you have set, to analyse and translate global trends, to know what works for a business such as yours, and to engage senior stakeholders in the process.</p><br />
                  <button className="btn btn-default btn-lg">Get in Touch</button>
              </div>
              <div class="col-sm-4">
                  <span class="glyphicon glyphicon-signal logo"></span>
              </div>
          </div>
      </div>
    )
  }
}