import React, { Component } from 'react'

export default class Values extends Component {
  render() {
    return (
        <div id="values" className="container-fluid  bg-grey">
            <div className="row">
                <div className="col-sm-4">
                    <span className="glyphicon glyphicon-globe logo slideanim"></span>
                </div>
                <div className="col-sm-8">
                    <h2>Our Vision and Values</h2><br />
                    <h4><strong>VISION:</strong> To be the first choice advisor for leading businesses, enabling change that results in our clients having the power to not only get ahead, but to stay there</h4><br />
                    <p><strong>OUR VALUES ARE CLEAR:</strong><ul><li>We drive change for good</li><li>We believe in making sense</li><li>We nurture tolerance</li><li>We live to innovate</li></ul></p>
                </div>
            </div>
        </div>
    )
  }
}