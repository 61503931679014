import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="container-fluid text-center">
                <div className="footer-content">
                    <a href="#myPage" title="To Top">
                        <span className="glyphicon glyphicon-chevron-up"></span>
                    </a>
                    <p><a href="/" title="Visit gmyz.com">GMYZ LIMITED</a> is a company with limited liability, registered in England &amp; Wales with registration number 12941182 and registered address 50 Turneville Road, LONDON W14 9PS, UK</p>
                    <p><a href="/privacy" title="Our privacy policy">Our Privacy Policy</a> </p>
                </div>
            </footer>
        )
    }
}