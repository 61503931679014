import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Nav from './components/nav'
import Jumbotron from './components/jumbotron';
import About from './components/about';
import Values from './components/values';
import Services from './components/services';
import Portfolio from './components/portfolio';
// import Pricing from './components/pricing';
import PrivacyStatement from './components/privacy';
import Contact from './components/contact';
import Map from './components/map';
import Footer from './components/footer';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
          </ul>

          <hr />

          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

function Home() {
  return (
    <div id="colorlib-page">
      <div id="container-wrap">
        <div id="colorlib-main">
          <Nav></Nav>
          <Jumbotron></Jumbotron>
          <About></About>
          <Values></Values>
          <Services></Services>
          <Portfolio></Portfolio>
          {/* <Pricing></Pricing> */}
          <Contact></Contact>
          <Map></Map>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}

function Privacy() {
  return (
    <div id="colorlib-page">
      <div id="container-wrap">
        <div id="colorlib-main">
          <Nav></Nav>
          <PrivacyStatement></PrivacyStatement>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}

export default App;