import React, { Component } from 'react'

export default class Jumbotron extends Component {
  render() {
    return (
      <div className="jumbotron text-center bg-grey">
          <h1>Gmyz Limited</h1>
          <p>open thinking</p>
          <form>
              <div className="input-group">
                  <input type="email" className="form-control" size="50" placeholder="Email Address" required />
                  <div className="input-group-btn">
                      <button type="button" className="btn btn-danger">Subscribe</button>
                  </div>
              </div>
          </form>
      </div>
    )
  }
}