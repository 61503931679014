import React, { Component } from 'react'

export default class Map extends Component {
  render() {
    return (
      <div id="map" className="container-fluid">
        <img src="/images/shibuya.jpeg" alt="map of company office" className="map-image w3-greyscale-min" />
      </div>
    )
  }
}
