import React, { Component } from 'react'

export default class Services extends Component {
  render() {
    return (
      <div id="services" className="container-fluid text-center">
        <h2>SERVICES</h2>
        <h4>What we offer</h4>
        <br />
        <div className="row slideanim">
          <div className="col-sm-4">
            <span className="glyphicon glyphicon-random logo-small"></span>
            <h4>STRATEGY</h4>
            <p>Become a data-driven business.</p>
          </div>
          <div className="col-sm-4">
            <span className="glyphicon glyphicon-signal logo-small"></span>
            <h4>VALUE</h4>
            <p>Through the power of data and AI.</p>
          </div>
          <div className="col-sm-4">
            <span className="glyphicon glyphicon-dashboard logo-small"></span>
            <h4>ADVANTAGE</h4>
            <p>Stay ahead in your field.</p>
          </div>
        </div>
        <br /><br />
        <div className="row slideanim">
          <div className="col-sm-4">
            <span className="glyphicon glyphicon-leaf logo-small"></span>
            <h4>GREEN</h4>
            <p>Embrace the alternative economy.</p>
          </div>
          <div className="col-sm-4">
            <span className="glyphicon glyphicon-user logo-small"></span>
            <h4>PEOPLE</h4>
            <p>Empower your organization.</p>
          </div>
          <div className="col-sm-4">
            <span className="glyphicon glyphicon-pushpin logo-small"></span>
            <h4>ROADMAP</h4>
            <p>The journey to your goals.</p>
          </div>
        </div>
      </div>
    )
  }
}